import { Component } from 'react';
import { Link } from 'react-router-dom';   
import { ScrollToView } from '../tools/Helpers';  
import { Social } from './Social';

import DoorLogo from "../assets/tots_door.png"; 
import NavBarIcon from "../assets/nav_icon_bar.png"; 

interface HeaderProps { 
    path: string;
    locale: string;
    active?: boolean;
    data: {
        header: MenuMessage[], 
        social: SocialMessage[];
        isReady: boolean,
    };
}

interface MenuMessage {
    url: string; 
    title: string;
    attr_title: string;
    classes: any;
    target: string;
}

interface SocialMessage {
    link: string;
    name: string;
    class: string;
}

interface MobileNavState {
    active: boolean;
  }

export class Header extends Component<HeaderProps, MobileNavState> { 
    constructor(props: any) {
        super(props);
        this.state = {
            active: false
        };
    }

    ExpandMobileNav = () => { 
        if (this.state.active === false) {
            this.setState({ active: true }); 
            document.body.classList.add('no-scroll');
        } else {
            this.setState({ active: false }); 
            document.body.classList.remove('no-scroll');
        }
    }

    ScrollToViewMobile = (e: Event, sectionId: string) => {
        e.preventDefault();
        let cleanSectionId = sectionId.toLowerCase();
        const section = document.getElementById(cleanSectionId); 
        if (section) { 
            this.setState({ active: false }); 
            document.body.classList.remove('no-scroll');
            section.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
    };

    render() {    
        const socialProps: { social: SocialMessage[]; isReady: boolean; } = {
            social:  this.props.data.social,
            isReady: this.props.data.isReady, 
        }; 
        const { header, isReady } = this.props.data;
        const event: Event = new Event('click'); 
        return ( 
            <>
                <nav id="app-nav" className="navbar navbar-expand-md">
                    <div className="icon-bar"><img src={NavBarIcon} className="img-fluid" width="330" height="37" alt="Tales of the Shire" /></div>
                    <div id="desktop-nav" className="container-fluid"> 
                        <div className="navbar-brand inview animated delay-2" data-ease="fadeInLeft">
                            <Link to={this.props.path}><img src={DoorLogo} className="img-fluid" width="300" height="291" alt="Tales of the Shire" /></Link>
                        </div>
                        <button className="navbar-toggler" type="button" onClick={this.ExpandMobileNav}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav me-auto cake inview animated" data-ease="fadeInLeft">
                                {isReady && header && header.map((nav: MenuMessage, index: number) => (
                                    nav.classes && nav.classes[0] === 'scroll' ? (
                                        <Link key={index} className={`link rieven ${(this.props.locale === nav.url) ? 'active' : ''}`} to={nav.url} onClick={() => ScrollToView(nav.attr_title ? nav.attr_title : nav.title, event)}>{nav.title}</Link>
                                    ) : (
                                        <Link key={index} className={`link rieven ${(this.props.locale === nav.url) ? 'active' : ''}`} to={nav.url} {...(nav.target ? { target: "_blank", rel: "noopener noreferrer" } : {})}>{nav.title}</Link>
                                    )
                                ))}
                            </ul> 
                            <div className="social-block inview animated delay-1" data-ease="fadeInRight">
                                <Social data={socialProps} />
                            </div> 
                        </div>
                    </div>             
                </nav>
                <nav id="mobile-nav" className={`navbar-collapse ${this.state.active ? 'active' : ''}`}>  
                    <div className={`hamburger-icon ${this.state.active ? 'active' : ''}`} onClick={this.ExpandMobileNav}>
                        <div className="icon-1"></div>
                        <div className="icon-2"></div>
                        <div className="icon-3"></div>
                        <div className="clear"></div>
                    </div> 
                    <div className="d-flex h-100 align-items-center justify-content-center text-center"> 
                        <div className="position-relative">
                            <div className="icon-bar w-80 mb-4"><img src={NavBarIcon} className="img-fluid" width="330" height="37" alt="Tales of the Shire" /></div>
                            <ul className="navbar-nav cake">
                                {isReady && header && header.map((nav: MenuMessage, index: number) => (
                                    nav.classes && nav.classes[0] === 'scroll' ? (
                                        <Link key={index} className={`link rieven ${(this.props.locale === nav.url) ? 'active' : ''}`} to={nav.url} onClick={() => this.ScrollToViewMobile(event, nav.attr_title ? nav.attr_title : nav.title)}>{nav.title}</Link>
                                    ) : (
                                        <Link key={index} className={`link rieven ${(this.props.locale === nav.url) ? 'active' : ''}`} to={nav.url}>{nav.title}</Link>
                                    )
                                ))} 
                            </ul>
                            <div className="social-block">
                                <Social data={socialProps} />
                            </div>
                        </div>
                    </div>
                </nav>     
            </>
        )
    }
}