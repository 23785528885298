import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import Dropdown from 'react-bootstrap/Dropdown';
import { useLanguage } from '../LanguageContext';
import languageConfig from '../json/languages.json';


interface Languages {
    [key: string]: {
        name: string,
        path: string
    }
} 
 
const languagesData: Languages = languageConfig; 

const LanguageSelector: React.FC = () => {
    const InitEffect = useRef(false);
    const userLanguage = navigator.language; 
    const { pathname } = useLocation();  
    const { setLanguage, setLanguagePath, language } = useLanguage();
    const [languageName, setLanguageName] = useState<string>('English'); 
 
    const handleChangeLanguage = (selectedLanguage: string, selectedLanguagePath: string, selectedCode: string) => {   
        setLanguage(selectedCode);
        setLanguagePath(selectedLanguagePath)
        setLanguageName(selectedLanguage); 
        window.scrollTo(0, 0);
        const elementToClick = document.getElementById('root'); 
        if (elementToClick) {
            elementToClick.click();
        }
    }; 

    useEffect(() => { 
        
        const Init = () => {
            // Parse URL to deirect Language
            var langPath: string[] = ['en'];
            const delimiter = '-';
            const parts = pathname.split('/');
            const languagePath = `/${parts[1]}`;
            const pagePath = parts.slice(2).join('/'); 
            if ((userLanguage !== 'en-US') && pathname === '/') {
                langPath = userLanguage.split(delimiter);
                let targetLang = langPath[0];
                if (targetLang && languagesData[targetLang] && languagesData[targetLang].path) {
                    setLanguage(targetLang);
                    setLanguagePath(languagesData[targetLang].path);
                    setLanguageName(languagesData[targetLang].name);
                }
            } else {
                Object.keys(languageConfig).forEach(lang => {
                    if ((languagePath === languagesData[lang].path) || pagePath) {
                        setLanguage(lang);
                        setLanguagePath(languagesData[lang].path);
                        setLanguageName(languagesData[lang].name);
                    }
                });
            }
        }
        
        if (!InitEffect.current) {
            Init();
        }
        return () => {
            InitEffect.current = true;
        };
        
    }, [pathname, setLanguage, setLanguagePath, userLanguage]);

    return ( 
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                { languageName }
            </Dropdown.Toggle>
            <Dropdown.Menu> 
                {languageConfig && Object.entries(languageConfig).map(([code, lang], index) => (
                    language !== code && ( 
                        <Link to={lang.path} key={index} className={`dropdown-item locale-switch ${language === code ? 'active' : ''}`} onClick={() => handleChangeLanguage(lang.name, lang.path, code)}>{lang.name}</Link>
                    ) 
            ))} 
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageSelector;